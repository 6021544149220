import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import React from 'react';
import { cleanUpSearchParams } from '../../lib/utils';

class CouponCodeListSearchForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultCouponCode: props.coupon_code,
      defaultCouponId: props.coupon_id,

      prevPropsCouponCode: null,
      prevPropsCouponId: null,

      //
      coupon_code: null,
      coupon_id: null,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.coupon_code !== prevState.prevPropsCouponCode ||
      nextProps.coupon_id !== prevState.prevPropsCouponId
    ) {
      return {
        prevPropsCouponCode: nextProps.coupon_code,
        prevPropsCouponId: nextProps.coupon_id,
        //
        coupon_code: nextProps.coupon_code,
        coupon_id: nextProps.coupon_id,
      };
    }

    return null;
  }

  handleReset = () => {
    const { defaultCouponCode, defaultCouponId } = this.state;
    const { handleReset, handleSearchSubmit } = this.props;
    this.setState({
      coupon_code: defaultCouponCode,
      coupon_id: defaultCouponId,
    });
    if (typeof handleReset === 'function') {
      handleReset();
    } else if (typeof handleSearchSubmit === 'function') {
      handleSearchSubmit({
        coupon_code: defaultCouponCode,
        coupon_id: defaultCouponId,
      });
    }
  };

  render() {
    const { handleSearchSubmit, readOnlyCouponId } = this.props;
    const { coupon_code, coupon_id } = this.state;
    return (
      <div>
        <Form>
          <FormGroup>
            <Label for="examplePassword">쿠폰 코드</Label>
            <Input
              placeholder="쿠폰 코드"
              onChange={(e) => {
                this.setState({
                  coupon_code: e.target.value,
                });
              }}
              onKeyPress={(e) => {
                if (e.charCode === 13) {
                  const filtered = cleanUpSearchParams(this.state);
                  handleSearchSubmit(filtered);
                }
              }}
              value={coupon_code || ''}
            />
          </FormGroup>
          <FormGroup>
            <Label for="examplePassword">쿠폰 ID</Label>
            <Input
              placeholder="쿠폰 ID"
              onChange={(e) => {
                this.setState({
                  coupon_id: e.target.value,
                });
              }}
              onKeyPress={(e) => {
                if (e.charCode === 13) {
                  const filtered = cleanUpSearchParams(this.state);
                  handleSearchSubmit(filtered);
                }
              }}
              readOnly={readOnlyCouponId}
              value={coupon_id || ''}
            />
          </FormGroup>

          <div className="text-right">
            <Button
              onClick={() => {
                const filtered = cleanUpSearchParams(this.state);
                handleSearchSubmit(filtered);
              }}
              style={{ marginRight: '8px' }}
            >
              찾아보기
            </Button>
            <Button onClick={this.handleReset}>검색 초기화</Button>
          </div>
        </Form>
      </div>
    );
  }
}
CouponCodeListSearchForm.defaultProps = {
  coupon_code: null,
  coupon_id: null,
  handleReset: null,
  handleSearchSubmit: null,
  readOnlyCouponId: false,
};
CouponCodeListSearchForm.propTypes = {
  coupon_code: PropTypes.string,
  coupon_id: PropTypes.number,
  handleReset: PropTypes.func,
  handleSearchSubmit: PropTypes.func,
  readOnlyCouponId: PropTypes.bool,
};
export default CouponCodeListSearchForm;
