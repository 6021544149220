import * as PropTypes from 'prop-types';

import { Col, Row } from 'reactstrap';
import {
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';

import React from 'react';

export default function PaginationListAndSizePerPageDropdown(props) {
  const { paginationProps } = props;
  return (
    <Row
      className="react-bootstrap-table-pagination"
      style={{ minHeight: '3.3em' }}
    >
      <Col md="3" xs="3" sm="3" lg="3">
        <SizePerPageDropdownStandalone {...paginationProps} />
      </Col>

      <Col
        md="6"
        xs="6"
        sm="6"
        lg="6"
        className="react-bootstrap-table-pagination-list"
      >
        <PaginationListStandalone {...paginationProps} />
      </Col>

      <Col md="3" xs="3" sm="3" lg="3" />
    </Row>
  );
}

PaginationListAndSizePerPageDropdown.defaultProps = {
  paginationProps: {},
};
PaginationListAndSizePerPageDropdown.propTypes = {
  paginationProps: PropTypes.any,
};
