import React, { Component } from 'react';
import {
  booleanFormatterFactory,
  courseFormatterFactory,
  dateTimeFormatterFactory,
  userIdFormatterFactory,
} from '../common/formatter/formatter';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import BootstrapTable from 'react-bootstrap-table-next';
import PaginationListAndSizePerPageDropdown from './common/PaginationListAndSizePerPageDropdown';
import PropTypes from 'prop-types';
import SubscriptionListSearchForm from './common/SubscriptionListSearchForm';
import _ from 'underscore';
import axios from 'axios';
import config from '../config';

let cancel;

class SubscriptionListTable extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.page !== prevState.prevPropsPage ||
      nextProps.sizePerPage !== prevState.prevPropsSizePerPage ||
      !_.isEqual(nextProps.search, prevState.prevPropsSearch)
    ) {
      if (typeof cancel === 'function') {
        cancel();
      }

      return {
        prevPropsPage: nextProps.page,
        prevPropsSizePerPage: nextProps.sizePerPage,
        prevPropsSearch: nextProps.search,
        //
        page: nextProps.page ? nextProps.page : 1,
        sizePerPage: nextProps.sizePerPage ? nextProps.sizePerPage : 20,
        search: { ...nextProps.search },
        dataOrError: null,
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    const search = {};

    this.state = {
      prevPropsPage: null,
      prevPropsSizePerPage: null,
      prevPropsSearch: null,

      //
      dataOrError: null,
      page: null,
      sizePerPage: 20,
      pagination: {},
      search: { ...search },
      expandRow: {
        renderer: (row) => (
          <div>
            <pre>{JSON.stringify(row, null, 2)}</pre>
          </div>
        ),
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
          if (isAnyExpands) {
            return <i className="fa fa-minus-square" />;
          }
          return <i className="fa fa-plus-square" />;
        },
        expandColumnRenderer: ({ expanded }) => {
          if (expanded) {
            return <i className="fa fa-minus-square" />;
          }
          return <i className="fa fa-plus-square" />;
        },
      },
      columns: [
        {
          dataField: 'id',
          text: '번호',
          headerStyle: { width: '8em', textAlign: 'center' },
          classes: 'text-center',
          // formatter: primaryIdFormatterFactory(row => row.id),
        },
        {
          dataField: 'name',
          text: '강의',
          formatter: courseFormatterFactory((row) => [
            row.course.id,
            row.course.name,
          ]),
          classes: 'text-truncate',
        },
        {
          dataField: 'user_id',
          text: '사용자 ID',
          headerStyle: { width: '8em', textAlign: 'center' },
          formatter: userIdFormatterFactory((row) => row.user_id),
          classes: 'text-center text-truncate',
        },
        {
          dataField: 'is_completed',
          text: '완강 여부',
          formatter: booleanFormatterFactory((row) => row.is_completed),
          headerStyle: { width: '6em', textAlign: 'center' },
          classes: 'text-center',
        },
        {
          dataField: 'is_expired',
          text: '만료 여부',
          formatter: booleanFormatterFactory((row) => row.is_expired),
          headerStyle: { width: '6em', textAlign: 'center' },
          classes: 'text-center',
        },
        {
          dataField: 'expires_at',
          text: '만료 일시',
          formatter: dateTimeFormatterFactory((row) => row.expires_at),
          headerStyle: { width: '14em', textAlign: 'center' },
          classes: 'text-center',
        },
        {
          dataField: 'created_at',
          text: '생성일시',
          formatter: dateTimeFormatterFactory((row) => row.created_at),
          classes: 'text-center',
          headerStyle: { width: '14em', textAlign: 'center' },
        },
      ],
    };
  }

  componentDidMount() {
    try {
      const { page, sizePerPage, search } = this.state;
      this.loadItemList(page, sizePerPage, search);
    } catch (e) {
      console.log(e);
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.state.dataOrError === null) {
      const { page, sizePerPage, search } = this.state;
      await this.loadItemList(page, sizePerPage, search);
    }
  };

  loadItemList = async (page, sizePerPage, search) => {
    try {
      const res = await axios.get(
        `${config.API_BASE}/admin/lectures/subscriptions`,
        {
          params: {
            page,
            per_page: sizePerPage,
            ...search,
          },
        },
      );

      if (!res) {
        return;
      }

      this.setState({
        dataOrError: res.data.data,
        // pagination: parsePaginationHeaders(res.headers),
        pagination: res.data.pagination,
      });
    } catch (e) {
      console.log(e);

      this.setState({
        dataOrError: [],
        pagination: {},
      });
    }
  };

  handleTableChange = async (type, { page, sizePerPage }) => {
    this.setState(() => ({
      dataOrError: null,
      page,
      sizePerPage,
    }));
  };

  handleSearchSubmit = (params) => {
    this.setState({
      search: {
        ...params,
      },
      dataOrError: null,
      page: 1,
    });
  };

  render() {
    const {
      dataOrError,
      sizePerPage,
      page,
      pagination: { total_count: totalSize },
      search,
      columns,
      expandRow,
    } = this.state;

    const options = {
      page,
      sizePerPage,
      custom: true,
      totalSize,
    };

    return (
      <div>
        <div>
          <SubscriptionListSearchForm
            handleSearchSubmit={this.handleSearchSubmit}
            readOnlyCourseGroupId={this.props.readOelyCourseGroupId}
            readOnlyUserId={this.props.readOnlyUserId}
            {...search}
          />
          <hr />
        </div>

        <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => (
            <div>
              {!this.props.hideTopPaginationArea && (
                <PaginationListAndSizePerPageDropdown
                  paginationProps={paginationProps}
                />
              )}

              <BootstrapTable
                remote
                keyField="id"
                data={dataOrError || []}
                columns={columns}
                onTableChange={this.handleTableChange}
                expandRow={expandRow}
                {...paginationTableProps}
              />

              {!this.props.hideBottomPaginationArea && (
                <PaginationListAndSizePerPageDropdown
                  paginationProps={paginationProps}
                />
              )}
            </div>
          )}
        </PaginationProvider>
      </div>
    );
  }
}

SubscriptionListTable.defaultProps = {
  hideBottomPaginationArea: false,
  page: 1,
  sizePerPage: 20,
  search: {},
  readOnlyCourseGroupId: false,
  readOnlyUserId: false,
};
SubscriptionListTable.propTypes = {
  hideBottomPaginationArea: PropTypes.bool,
  page: PropTypes.number,
  readOnlyCourseGroupId: PropTypes.bool,
  readOnlyUserId: PropTypes.bool,
  sizePerPage: PropTypes.number,
  search: PropTypes.shape({
    user_id: PropTypes.number,
  }),
};

export default SubscriptionListTable;
