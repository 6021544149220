import CouponActivityListPage from './CouponActivityListPage';
import CouponCodeDetailPage from './CouponCodeDetailPage';
import CouponCodeList from './CouponCodeList';
import CouponCouponCodeListPage from './CouponCodeListPage';
import { CouponGroupCreate } from './CouponGroupCreate';
import { CouponDashboard } from './CouponDashboard';
import CouponDetailPage from './CouponGroupDetailPage';
import CouponListPage from './CouponGroupListPage';
import CouponUserCouponListPage from './CouponUserCouponListPage';
import UserCouponDetailPage from './UserCouponDetailPage';
import UserCouponListPage from './UserCouponListPage';
import VoucherCreate from './VoucherCreate';
import VoucherListPage from './VoucherListPage';

export const routes = [
  {
    path: '/coupons',
    name: '쿠폰',
    exact: true,
    component: CouponDashboard,
  },
  {
    path: '/coupons/coupon-groups/create',
    name: '쿠폰 생성',
    exact: true,
    component: CouponGroupCreate,
  },
  {
    path: '/coupons/coupon-groups/list',
    name: '쿠폰 목록',
    exact: true,
    component: CouponListPage,
  },
  {
    path: '/coupons/coupon-groups/:id',
    name: '쿠폰 상세',
    exact: true,
    component: CouponDetailPage,
  },
  {
    path: '/coupons/coupon-groups/:id/coupon-codes',
    name: '쿠폰 코드',
    exact: true,
    component: CouponCouponCodeListPage,
  },
  {
    path: '/coupons/coupon-groups/:id/user-coupon-codes',
    name: '등록 쿠폰',
    exact: true,
    component: CouponUserCouponListPage,
  },
  {
    path: '/coupons/coupon-codes/list',
    name: '쿠폰 목록',
    exact: true,
    component: CouponCodeList,
  },
  {
    path: '/coupons/coupon-codes/:couponCodeId',
    name: '쿠폰 코드 상세',
    exact: true,
    component: CouponCodeDetailPage,
  },
  {
    path: '/coupons/user-coupon-codes/list',
    name: '등록 쿠폰',
    exact: true,
    component: UserCouponListPage,
  },
  {
    path: '/coupons/user-coupon-codes/:userCouponId',
    name: '등록 쿠폰 상세',
    exact: true,
    component: UserCouponDetailPage,
  },
  {
    path: '/coupons/coupon-activities/list',
    name: '쿠폰 로그',
    exact: true,
    component: CouponActivityListPage,
  },
  {
    path: '/coupons/vouchers/create',
    name: '이용권 생성',
    exact: true,
    component: VoucherCreate,
  },
  {
    path: '/coupons/vouchers/list',
    name: '이용권 목록',
    exact: true,
    component: VoucherListPage,
  },
];
