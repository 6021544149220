import React from 'react';
import { inject, observer } from 'mobx-react';
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { Link } from 'react-router-dom';
import Gravatar from 'react-gravatar';

const TheHeaderDropdown = inject('store')(
  observer((props) => {
    const { handleLogout } = props;
    const { store } = props;

    return (
      <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
        <CDropdownToggle className="c-header-nav-link" caret={false}>
          <div className="c-avatar">
            {store.profile ? (
              <Gravatar email={store.profile.email} className="c-avatar-img" />
            ) : (
              <CImg
                src={'https://via.placeholder.com/300.png'}
                className="c-avatar-img"
              />
            )}
          </div>
        </CDropdownToggle>
        <CDropdownMenu className="pt-0" placement="bottom-end">
          <CDropdownItem header tag="div" color="light" className="text-center">
            <strong>Settings</strong>
          </CDropdownItem>
          <CDropdownItem>
            <CIcon name="cil-user" className="mfe-2" />
            <Link to="/profile">Profile</Link>
          </CDropdownItem>
          <CDropdownItem divider />
          <CDropdownItem onClick={(e) => handleLogout(e)}>
            <CIcon name="cil-lock-locked" className="mfe-2" />
            Logout
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    );
  }),
);

export default TheHeaderDropdown;
