import React, { Component } from 'react';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import BootstrapTable from 'react-bootstrap-table-next';
import { CBadge } from '@coreui/react';
import CouponCodeListSearchForm from './common/CouponCodeListSearchForm';
import PaginationListAndSizePerPageDropdown from './common/PaginationListAndSizePerPageDropdown';
import PropTypes from 'prop-types';
import _ from 'underscore';
import axios from 'axios';
import config from '../config';
import { dateTimeFormatterFactory } from '../common/formatter/formatter';

let cancel;

class CouponActivityListTable extends Component {
  constructor(props) {
    super(props);

    const { couponId } = this.props;
    let search = {};
    if (couponId) {
      search = {
        coupon_group_id: couponId,
      };
    }

    this.state = {
      prevPropsPage: null,
      prevPropsSizePerPage: null,
      prevPropsSearch: null,

      //
      dataOrError: null,
      page: null,
      sizePerPage: 20,
      pagination: {},
      search: { ...search },
      expandRow: {
        renderer: (row) => (
          <div>
            <pre>{JSON.stringify(row, null, 2)}</pre>
          </div>
        ),
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
          if (isAnyExpands) {
            return <i className="fa fa-minus-square" />;
          }
          return <i className="fa fa-plus-square" />;
        },
        expandColumnRenderer: ({ expanded }) => {
          if (expanded) {
            return <i className="fa fa-minus-square" />;
          }
          return <i className="fa fa-plus-square" />;
        },
      },
      columns: [
        {
          dataField: 'id',
          text: '번호',
          headerStyle: { width: '6em', textAlign: 'center' },
          classes: 'text-center',
          // formatter: primaryIdFormatterFactory(row => row.id),
        },
        {
          dataField: 'actor',
          text: 'Actor',
          formatter: (cell, row) => (
            <>
              {row.actor.type === 'admin' && (
                <div className="badge-valign">
                  관리자 <CBadge color="primary">{row.actor.id}</CBadge>
                </div>
              )}
              {row.actor.type === 'user' && (
                <div className="badge-valign">
                  회원 <CBadge color="success">{row.actor.id}</CBadge>
                </div>
              )}
            </>
          ),
        },
        {
          dataField: 'target',
          text: '대상',
          formatter: (cell, row) => {
            const renderSwitch = (target) => {
              switch (target.type) {
                case 'user_coupon':
                  return (
                    <>
                      사용자 쿠폰코드{' '}
                      <CBadge color="primary">{target.id}</CBadge>
                    </>
                  );
                case 'coupon_group':
                  return (
                    <>
                      쿠폰 <CBadge color="primary">{target.id}</CBadge>
                    </>
                  );
                case 'coupon_code':
                  return (
                    <>
                      쿠폰 코드 <CBadge color="success">{target.id}</CBadge>
                    </>
                  );
                default:
                  return <>{target.id}</>;
              }
            };
            return (
              <div className="badge-valign">{renderSwitch(row.target)}</div>
            );
          },
        },
        {
          dataField: 'action',
          text: '작업',
          classes: 'text-center',
        },
        {
          dataField: 'params',
          text: 'Params',
          classes: 'text-center',
          formatter: (cell) => <pre>{JSON.stringify(cell, null, 2)}</pre>,
        },
        {
          dataField: 'result',
          text: '결과',
          classes: 'text-center',
          formatter: (cell) => (
            <CBadge color={cell === 'success' ? 'success' : 'danger'}>
              {cell}
            </CBadge>
          ),
        },
        {
          dataField: 'created_at',
          text: '생성일시',
          formatter: dateTimeFormatterFactory((row) => row.created_at),
          classes: 'text-center',
          headerStyle: { width: '14em', textAlign: 'center' },
        },
      ],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.page !== prevState.prevPropsPage ||
      nextProps.sizePerPage !== prevState.prevPropsSizePerPage ||
      !_.isEqual(nextProps.search, prevState.prevPropsSearch)
    ) {
      if (typeof cancel === 'function') {
        cancel();
      }

      return {
        prevPropsPage: nextProps.page,
        prevPropsSizePerPage: nextProps.sizePerPage,
        prevPropsSearch: nextProps.search,
        //
        page: nextProps.page ? nextProps.page : 1,
        sizePerPage: nextProps.sizePerPage ? nextProps.sizePerPage : 20,
        search: { ...nextProps.search },
        dataOrError: null,
      };
    }

    return null;
  }

  componentDidMount() {
    try {
      const { page, sizePerPage, search } = this.state;
      this.loadItemList(page, sizePerPage, search);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  reloadItemList = () => {
    const { page, sizePerPage, search } = this.state;
    this.loadItemList(page, sizePerPage, search);
  };

  loadItemList = async (page, sizePerPage, search) => {
    try {
      const res = await axios.get(
        `${config.API_BASE}/admin/api/coupons/coupon-activities`,
        {
          params: {
            page,
            per_page: sizePerPage,
            ...search,
          },
        },
      );

      if (!res) {
        return;
      }

      this.setState({
        dataOrError: res.data.data,
        pagination: res.data.pagination,
      });
    } catch (e) {
      console.log(e);

      this.setState({
        dataOrError: [],
        pagination: {},
      });
    }
  };

  componentDidUpdate = async () => {
    const { dataOrError } = this.state;
    if (dataOrError === null) {
      const { page, sizePerPage, search } = this.state;
      await this.loadItemList(page, sizePerPage, search);
    }
  };

  handleTableChange = async (type, { page, sizePerPage }) => {
    this.setState(() => ({
      dataOrError: null,
      page,
      sizePerPage,
    }));
  };

  handleSearchSubmit = (params) => {
    console.log('handleSearchSubmit', params);
    this.setState({
      search: {
        ...params,
      },
      dataOrError: null,
      page: 1,
    });
  };

  render() {
    const {
      dataOrError,
      sizePerPage,
      page,
      pagination: { total_count: totalSize },
      search,
      columns,
      expandRow,
    } = this.state;

    const {
      hideTopPaginationArea,
      hideBottomPaginationArea,
      readOnlyCouponId,
    } = this.props;

    const options = {
      page,
      sizePerPage,
      custom: true,
      totalSize,
    };

    return (
      <div>
        <div>
          <CouponCodeListSearchForm
            handleSearchSubmit={this.handleSearchSubmit}
            readOnlyCouponId={readOnlyCouponId}
            {...search}
          />
          <hr />
        </div>

        <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => (
            <div>
              {!hideTopPaginationArea && (
                <PaginationListAndSizePerPageDropdown
                  paginationProps={paginationProps}
                />
              )}

              <BootstrapTable
                remote
                keyField="id"
                data={dataOrError || []}
                columns={columns}
                onTableChange={this.handleTableChange}
                expandRow={expandRow}
                {...paginationTableProps}
              />

              {!hideBottomPaginationArea && (
                <PaginationListAndSizePerPageDropdown
                  paginationProps={paginationProps}
                />
              )}
            </div>
          )}
        </PaginationProvider>
      </div>
    );
  }
}

CouponActivityListTable.defaultProps = {
  couponId: null,
  page: 1,
  sizePerPage: 20,
  search: null,
  readOnlyCouponId: false,
  hideBottomPaginationArea: false,
  hideTopPaginationArea: false,
};
CouponActivityListTable.propTypes = {
  couponId: PropTypes.number,
  page: PropTypes.number,
  sizePerPage: PropTypes.number,
  search: PropTypes.shape({
    coupon_code: PropTypes.string,
    coupon_group_id: PropTypes.number,
    handleReset: PropTypes.func,
    readOnlyCouponId: PropTypes.bool,
  }),
  readOnlyCouponId: PropTypes.bool,
  hideBottomPaginationArea: PropTypes.bool,
  hideTopPaginationArea: PropTypes.bool,
};
export default CouponActivityListTable;
