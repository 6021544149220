import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import UserCouponCodeListTable from '../../components/UserCouponCodeListTable';
import axios from 'axios';
import config from '../../config';
import { withRouter } from 'react-router-dom';
import CouponGroupNav from '../../components/CouponGroupNav';

const CouponUserCouponListPage = (props) => {
  const { id: couponGroupId } = props.match.params;

  const [couponStats, setCouponStats] = useState({});

  const getCouponStats = async () => {
    const endpoint = `${config.API_BASE}/admin/api/coupons/coupons/${couponGroupId}/stats`;

    try {
      const res = await axios.get(endpoint);
      setCouponStats(res.data.data);
    } catch (e) {
      console.log(e);
      setCouponStats(null);
    }
  };

  useEffect(() => {
    getCouponStats();
  }, []);

  return (
    <>
      <CouponGroupNav couponGroupId={couponGroupId} couponStats={couponStats} />

      <CRow>
        <CCol>
          <CCard>
            <CCardHeader>
              <i className="fa fa-align-justify" /> 등록 쿠폰 목록
            </CCardHeader>
            <CCardBody>
              <UserCouponCodeListTable
                search={{ coupon_group_id: couponGroupId }}
                readOnlyCouponId={true}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default withRouter(CouponUserCouponListPage);
