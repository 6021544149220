import { UserDashboard } from './UserDashboard';
import { UserDetailPage } from './UserDetailPage';
import { UsersListPage } from './UsersListPage';

export const routes = [
  {
    path: '/users',
    name: '회원',
    exact: true,
    component: UserDashboard,
  },
  {
    path: '/users/list',
    name: '목록',
    exact: true,
    component: UsersListPage,
  },
  {
    path: '/users/:userId',
    name: '상세',
    exact: true,
    component: UserDetailPage,
  },
];
