export const navigation = [
  {
    _tag: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/coupons',
    icon: 'cil-speedometer',
    badge: {
      color: 'info',
      text: '준비중',
    },
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['쿠폰 관리'],
  },
  {
    _tag: 'CSidebarNavItem',
    name: '쿠폰 생성',
    to: '/coupons/coupon-groups/create',
    icon: 'cil-pencil',
  },
  {
    _tag: 'CSidebarNavItem',
    name: '쿠폰',
    to: '/coupons/coupon-groups/list',
    icon: 'cil-list',
  },
  /*
  {
    _tag: 'CSidebarNavItem',
    name: '쿠폰 코드',
    to: '/coupons/coupons/list',
    icon: 'cil-list',
  },
   */
  {
    _tag: 'CSidebarNavItem',
    name: '등록 쿠폰',
    to: '/coupons/user-coupon-codes/list',
    icon: 'cil-list',
  },
  {
    _tag: 'CSidebarNavItem',
    name: '이용권 생성',
    to: '/coupons/vouchers/create',
    icon: 'cil-pencil',
  },
  {
    _tag: 'CSidebarNavItem',
    name: '이용권',
    to: '/coupons/vouchers/list',
    icon: 'cil-list',
  },
  {
    _tag: 'CSidebarNavItem',
    name: '쿠폰 로그',
    to: '/coupons/coupon-activities/list',
    icon: 'cil-list',
  },
];
