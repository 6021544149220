import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config';

export const CourseDetailPage = (props) => {
  const {
    match: {
      params: { courseId },
    },
  } = props;
  const [courseDetail, setCourseDetail] = useState(true);

  useEffect(() => {
    const endpoint = `${config.API_BASE}/admin/api/course/v1/courses/${courseId}`;
    axios.get(endpoint).then((res) => {
      setCourseDetail(res.data.data);
    });
  }, [courseId]);

  return (
    <>
      {/*
      {courseDetail && (
        <>
          <CCard>
            <CCardHeader>
              <div className="badge-valign">
                강의 상세 <CBadge color="primary">{courseId}</CBadge>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol md={2}>ID</CCol>
                <CCol>{courseDetail.id}</CCol>
              </CRow>
              <CRow>
                <CCol md={2}>강의명</CCol>
                <CCol>{courseDetail.title}</CCol>
              </CRow>
              <CRow>
                <CCol md={2}>가격</CCol>
                <CCol>
                  {courseDetail.price && (
                    <>
                      <strong>{courseDetail.price}</strong> 원
                    </>
                  )}
                </CCol>
              </CRow>
              <CRow>
                <CCol md={2}>상태</CCol>
                <CCol>
                  {courseDetail.state === 'published' ? (
                    <CBadge color="success">{courseDetail.state}</CBadge>
                  ) : (
                    <CBadge color="danger">{courseDetail.state}</CBadge>
                  )}
                </CCol>
              </CRow>
              <CRow>
                <CCol md={2}>생성 일시</CCol>
                <CCol>
                  {moment(courseDetail.created_at).format(
                    'YYYY-mm-dd hh:mm:ss',
                  )}
                </CCol>
              </CRow>
              <CRow>
                <CCol md={2}>최종 수정 일시</CCol>
                <CCol>
                  {moment(courseDetail.updated_at).format(
                    'YYYY-mm-dd hh:mm:ss',
                  )}
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
          <CCard>
            <CCardHeader>강의 수강 목록</CCardHeader>
            <CCardBody>
              <SubscriptionListTable
                search={{ course_group_id: courseId }}
                readOnlyCourseGroupId={true}
              />
            </CCardBody>
          </CCard>
        </>
      )}
      */}
    </>
  );
};
