import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from '@coreui/react';
import React, { Component } from 'react';
import {
  booleanFormatterFactory,
  couponCodeFormatterFactory,
  couponFormatterFactory,
  dateTimeFormatterFactory,
  userCouponFormatterFactory,
  userIdFormatterFactory,
} from '../common/formatter/formatter';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import BootstrapTable from 'react-bootstrap-table-next';
import { Button } from 'reactstrap';
import PaginationListAndSizePerPageDropdown from './common/PaginationListAndSizePerPageDropdown';
import UserCouponListSearchForm from './common/UserCouponListSearchForm';
import _ from 'underscore';
import axios from 'axios';
import config from '../config';

let cancel;

export default class UserCouponCodeListTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteConfirmModal: false,
      deleteTargetUserId: null,
      deleteTargetCouponCode: null,
      deleteTargetUserCouponId: null,

      prevPropsPage: null,
      prevPropsSizePerPage: null,
      prevPropsSearch: null,

      //
      dataOrError: null,
      page: null,
      sizePerPage: 20,
      pagination: {},
      search: {},
      expandRow: {
        renderer: (row) => (
          <div>
            <pre>{JSON.stringify(row, null, 2)}</pre>
          </div>
        ),
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
          if (isAnyExpands) {
            return <i className="fa fa-minus-square" />;
          }
          return <i className="fa fa-plus-square" />;
        },
        expandColumnRenderer: ({ expanded }) => {
          if (expanded) {
            return <i className="fa fa-minus-square" />;
          }
          return <i className="fa fa-plus-square" />;
        },
      },
      columns: [
        {
          dataField: 'id',
          text: '번호',
          headerStyle: { width: '8em', textAlign: 'center' },
          classes: 'text-center',
          formatter: userCouponFormatterFactory((row) => row.id),
        },
        {
          dataField: 'code',
          text: '쿠폰 코드',
          formatter: couponCodeFormatterFactory((row) => {
            try {
              return [row.coupon_code.id, row.coupon_code.code];
            } catch (e) {
              return [null, null];
            }
          }),
          classes: 'text-truncate',
        },
        {
          dataField: 'name',
          text: '쿠폰',
          formatter: couponFormatterFactory((row) => {
            try {
              return [
                row.coupon_code.coupon_group.id,
                row.coupon_code.coupon_group.name,
              ];
            } catch (e) {
              return [null, null];
            }
          }),
          classes: 'text-truncate',
        },
        {
          dataField: 'user_id',
          text: '사용자 ID',
          headerStyle: { width: '8em', textAlign: 'center' },
          formatter: userIdFormatterFactory((row) => row.user_id),
          classes: 'text-center text-truncate',
        },
        {
          dataField: 'is_used',
          text: '사용 여부',
          formatter: booleanFormatterFactory((row) => row.is_used),
          classes: 'text-center',
          headerStyle: { width: '6em', textAlign: 'center' },
        },
        {
          dataField: 'expires_at',
          text: '사용 만료일시',
          formatter: dateTimeFormatterFactory((row) => row.expires_at),
          classes: 'text-center',
          headerStyle: { width: '14em', textAlign: 'center' },
        },
        {
          dataField: 'created_at',
          text: '등록일시',
          formatter: dateTimeFormatterFactory((row) => row.created_at),
          classes: 'text-center',
          headerStyle: { width: '14em', textAlign: 'center' },
        },
        {
          dataField: 'none',
          text: 'click me',
          formatter: (cell, row, rowIndex, formatExtraData) => (
            <div>
              <Button
                color="danger"
                outline
                size="sm"
                onClick={() => {
                  this.setState({
                    deleteTargetUserId: row.user_id,
                    deleteTargetCouponCode: row.coupon_code.code,
                    deleteTargetUserCouponId: row.id,
                  });

                  this.showUserCouponDeleteConfirmModal();
                }}
              >
                등록 취소
              </Button>
            </div>
          ),
          classes: 'text-center',
          headerStyle: { width: '8em', textAlign: 'center' },
        },
      ],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.page !== prevState.prevPropsPage ||
      nextProps.sizePerPage !== prevState.prevPropsSizePerPage ||
      !_.isEqual(nextProps.search, prevState.prevPropsSearch)
    ) {
      if (typeof cancel === 'function') {
        cancel();
      }

      return {
        prevPropsPage: nextProps.page,
        prevPropsSizePerPage: nextProps.sizePerPage,
        prevPropsSearch: nextProps.search,
        //
        page: nextProps.page ? nextProps.page : 1,
        sizePerPage: nextProps.sizePerPage ? nextProps.sizePerPage : 20,
        search: { ...nextProps.search },
        dataOrError: null,
      };
    }

    return null;
  }

  componentDidMount() {
    try {
      const { page, sizePerPage, search } = this.state;
      this.loadItemList(page, sizePerPage, search);
    } catch (e) {
      console.log(e);
    }
  }

  showUserCouponDeleteConfirmModal = () => {
    this.setState({
      deleteConfirmModal: true,
    });
  };

  handleUserCouponDelete = (userCouponId) => {
    this.setState({
      deleteConfirmModal: false,
    });

    const endpoint = `${config.API_BASE}/admin/api/coupons/user-coupons/${userCouponId}`;
    axios
      .delete(endpoint)
      .then((res) => {
        this.reloadItemList();
      })
      .catch((error) => {
        alert(error);
      });
  };

  reloadItemList = () => {
    const { page, sizePerPage, search } = this.state;
    this.loadItemList(page, sizePerPage, search);
  };

  loadItemList = async (page, sizePerPage, search) => {
    try {
      const res = await axios.get(
        `${config.API_BASE}/admin/api/coupons/user-coupons`,
        {
          params: {
            page,
            per_page: sizePerPage,
            ...search,
          },
        },
      );

      if (!res) {
        return;
      }

      this.setState({
        dataOrError: res.data.data,
        pagination: res.data.pagination,
      });
    } catch (e) {
      console.log(e);

      this.setState({
        dataOrError: [],
        pagination: {},
      });
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.state.dataOrError === null) {
      const { page, sizePerPage, search } = this.state;
      await this.loadItemList(page, sizePerPage, search);
    }
  };

  handleTableChange = async (type, { page, sizePerPage }) => {
    this.setState(() => ({
      dataOrError: null,
      page,
      sizePerPage,
    }));
  };

  handleSearchSubmit = (params) => {
    console.log('handleSearchSubmit', params);
    this.setState({
      search: {
        ...params,
      },
      dataOrError: null,
      page: 1,
    });
  };

  render() {
    const {
      dataOrError,
      sizePerPage,
      page,
      pagination: { total_count: totalSize },
      search,
      columns,
      expandRow,
      deleteConfirmModal,
      deleteTargetUserId,
      deleteTargetCouponCode,
      deleteTargetUserCouponId,
    } = this.state;

    console.log(this.state);

    const options = {
      page,
      sizePerPage,
      custom: true,
      totalSize,
    };
    console.log(options);

    return (
      <div>
        <>
          <CModal
            show={deleteConfirmModal}
            onClose={() => {
              this.setState({ deleteConfirmModal: false });
            }}
          >
            <CModalHeader closeButton>사용자 쿠폰코드 삭제 확인</CModalHeader>
            <CModalBody>
              <p>
                사용자: {deleteTargetUserId} 에게 등록된 쿠폰 코드:{' '}
                {deleteTargetCouponCode} 항목을 삭제하시겠습니까?
              </p>
              <p>
                사용자의 쿠폰함에서 해당 쿠폰이 사라지며 복구할 수 없습니다.
              </p>
            </CModalBody>
            <CModalFooter>
              <CButton
                color="primary"
                onClick={() =>
                  this.handleUserCouponDelete(deleteTargetUserCouponId)
                }
              >
                삭제
              </CButton>{' '}
              <CButton
                color="secondary"
                onClick={() => {
                  this.setState({ deleteConfirmModal: false });
                }}
              >
                취소
              </CButton>
            </CModalFooter>
          </CModal>
        </>

        <div>
          <UserCouponListSearchForm
            handleSearchSubmit={this.handleSearchSubmit}
            readOnlyUserId={this.props.readOnlyUserId}
            readOnlyCouponId={this.props.readOnlyCouponId}
            {...search}
          />
          <hr />
        </div>

        <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => (
            <div>
              {!this.props.hideTopPaginationArea && (
                <PaginationListAndSizePerPageDropdown
                  paginationProps={paginationProps}
                />
              )}

              <BootstrapTable
                remote
                keyField="id"
                data={dataOrError || []}
                columns={columns}
                onTableChange={this.handleTableChange}
                expandRow={expandRow}
                {...paginationTableProps}
              />

              {!this.props.hideBottomPaginationArea && (
                <PaginationListAndSizePerPageDropdown
                  paginationProps={paginationProps}
                />
              )}
            </div>
          )}
        </PaginationProvider>
      </div>
    );
  }
}
