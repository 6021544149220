import { CBadge } from '@coreui/react';
import { Link } from 'react-router-dom';
import React from 'react';
import { UserInline } from '../../components/UserInline';
import moment from 'moment';

export const userIdFormatter = (cell, row, rowIndex, formatExtraData) => {
  const { member_id } = row;
  return (
    <div>
      <i className="fa fa-user" /> {member_id}
    </div>
  );
};

export const booleanFormatterFactory = (booleanFieldExtractor) => {
  return (cell, row, rowIndex, formatExtraData) => {
    const value = booleanFieldExtractor(row);
    return (
      <div>
        {value ? (
          <i className="fa fa-check-circle text-success" />
        ) : (
          <i className="fa fa-circle text-danger" />
        )}
      </div>
    );
  };
};

export const dateTimeFormatterFactory = (
  dateTimeExtractor,
  format = 'YYYY-MM-DD HH:mm:ss',
) => {
  return (cell, row, rowIndex, formatExtraData) => {
    const dateTimeStr = dateTimeExtractor(row);
    const datetime = moment(dateTimeStr);
    return (
      <>
        {datetime.isValid() ? (
          <div>{datetime.format(format)}</div>
        ) : (
          <span>-</span>
        )}
      </>
    );
  };
};

export const primaryIdFormatterFactory = (primaryIdExtractor) => {
  return (cell, row, rowIndex, formatExtraData) => {
    const primaryId = primaryIdExtractor(row);
    return (
      <div>
        <i
          className="fa fa-hashtag text-primary"
          style={{ marginRight: '4px' }}
        />
        <Link to={`/users/users-detail/${primaryId}`}>{primaryId}</Link>
      </div>
    );
  };
};

export const couponCodeFormatterFactory = (idExtractor) => {
  return (cell, row, rowIndex, formatExtraData) => {
    const [couponId, couponCode] = idExtractor(row);
    return (
      <div>
        <i className="fa fa-tag text-primary" style={{ marginRight: '4px' }} />
        <Link to={`/coupons/coupon-codes/${couponId}`}>
          <code>{couponCode}</code>
        </Link>
      </div>
    );
  };
};

export const couponFormatterFactory = (idExtractor) => {
  return (cell, row, rowIndex, formatExtraData) => {
    const [couponId, couponName] = idExtractor(row);
    return (
      <div className="badge-valign">
        <CBadge color="primary">{couponId}</CBadge>{' '}
        <Link to={`/coupons/coupon-groups/${couponId}`}>{couponName}</Link>
      </div>
    );
  };
};

export const courseFormatterFactory = (idExtractor) => {
  return (cell, row, rowIndex, formatExtraData) => {
    const [courseGroupId, courseName] = idExtractor(row);
    return (
      <div className="badge-valign">
        <Link to={`/courses/${courseGroupId}`}>
          <CBadge color="primary">{courseGroupId}</CBadge> {courseName}
        </Link>
      </div>
    );
  };
};

export const userCouponFormatterFactory = (idExtractor) => {
  return (cell, row, rowIndex, formatExtraData) => {
    const userCouponId = idExtractor(row);
    return (
      <div className="badge-valign">
        <CBadge color="primary">{userCouponId}</CBadge>{' '}
        <Link to={`/coupons/user-coupon-codes/${userCouponId}`}>등록쿠폰</Link>
      </div>
    );
  };
};

export const userIdFormatterFactory = (userIdExtractor) => {
  return (cell, row, rowIndex, formatExtraData) => {
    const userId = userIdExtractor(row);
    return <UserInline userId={userId} />;
  };
};
