import {
  CCollapse,
  CNav,
  CNavItem,
  CNavLink,
  CNavbar,
  CNavbarBrand,
  CNavbarNav,
  CToggler,
} from '@coreui/react';

import React from 'react';

const VoucherNav = () => (
  <>
    <CNavbar
      expandable="sm"
      color="faded"
      light
      style={{
        border: '1px solid #d8dbe0',
        backgroundColor: '#ffffff',
        borderRadius: '0.25rem',
        marginBottom: '1.5rem',
      }}
    >
      <CToggler inNavbar />
      <CNavbarBrand>이용권</CNavbarBrand>
      <CCollapse show navbar>
        <CNavbarNav className="ml-auto" />

        <CNav>
          <CNavItem>
            <CNavLink active to="/coupons/vouchers/list">
              이용권 목록
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink to="/coupons/vouchers/create">이용권 생성</CNavLink>
          </CNavItem>
        </CNav>
      </CCollapse>
    </CNavbar>
  </>
);

VoucherNav.defaultProps = {};

VoucherNav.propTypes = {};

export default VoucherNav;
