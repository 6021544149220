import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';

import React from 'react';
import { cleanUpSearchParams } from '../../lib/utils';

class CouponListSearchForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prevPropsName: null,

      //
      name: null,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.name !== prevState.prevPropsName) {
      return {
        prevPropsName: nextProps.name,
        //
        name: nextProps.name,
      };
    }

    return null;
  }

  handleReset = () => {
    const { handleReset, handleSearchSubmit } = this.props;
    this.setState({ member_id: null, email: null, name: null });
    if (typeof handleReset === 'function') {
      handleReset();
    } else if (typeof handleSearchSubmit === 'function') {
      handleSearchSubmit({});
    }
  };

  render() {
    const { handleSearchSubmit } = this.props;
    const { name } = this.state;

    return (
      <div>
        <Form>
          <FormGroup>
            <Label for="examplePassword">이름</Label>
            <Input
              placeholder="이름"
              onChange={(e) => {
                this.setState({
                  name: e.target.value,
                });
              }}
              onKeyPress={(e) => {
                if (e.charCode === 13) {
                  const filtered = cleanUpSearchParams(this.state);
                  handleSearchSubmit(filtered);
                }
              }}
              value={name || ''}
            />
          </FormGroup>

          <div className="text-right">
            <Button
              onClick={() => {
                const filtered = cleanUpSearchParams(this.state);
                handleSearchSubmit(filtered);
              }}
              style={{ marginRight: '8px' }}
            >
              찾아보기
            </Button>
            <Button onClick={this.handleReset}>검색 초기화</Button>
          </div>
        </Form>
      </div>
    );
  }
}

CouponListSearchForm.defaultProps = {
  name: null,
  handleReset: null,
  handleSearchSubmit: null,
};
CouponListSearchForm.propTypes = {
  name: PropTypes.string,
  handleReset: PropTypes.func,
  handleSearchSubmit: PropTypes.func,
};
export default CouponListSearchForm;
