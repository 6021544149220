import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react';
import React from 'react';
import UsersListTable from '../../components/UsersListTable';

export const UsersListPage = () => {
  return (
    <>
      <CRow>
        <CCol>
          <CCard>
            <CCardHeader>회원 목록</CCardHeader>
            <CCardBody>
              <UsersListTable />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};
