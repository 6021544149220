import {
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
} from '@coreui/react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';

import { CouponGroupDetail } from '../../components/CouponGroupDetail';
import UserCouponCodeListTable from '../../components/UserCouponCodeListTable';
import axios from 'axios';
import config from '../../config';
import moment from 'moment';

const CouponCodeDetailPage = (props) => {
  const {
    match: {
      params: { couponCodeId },
    },
  } = props;
  const [couponCodeDetail, setCouponCodeDetail] = useState({});
  const [couponDetail, setCouponDetail] = useState(null);
  const [courseData, setCourseData] = useState(null);

  const getCouponDetail = (couponCodeId) => {
    const endpoint = `${config.API_BASE}/admin/api/coupons/coupon-codes/${couponCodeId}`;
    axios
      .get(endpoint)
      .then((res) => {
        setCouponCodeDetail(res.data.data);
        const couponGroupId = res.data.data.coupon_group.id;
        const couponDetailEndpoint = `${config.API_BASE}/admin/api/coupons/coupons/${couponGroupId}`;
        axios
          .get(couponDetailEndpoint)
          .then((res) => {
            setCouponDetail(res.data.data);
          })
          .catch((err) => {
            console.log(err);
            alert(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCourseData = () => {
    const endpoint = `${config.API_BASE}/admin/api/course/v1/courses?per_page=100`;
    axios
      .get(endpoint)
      .then((res) => {
        setCourseData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCouponDetail(couponCodeId);
    getCourseData();
  }, [couponCodeId]);

  return (
    <>
      {couponDetail && (
        <>
          <CCard>
            <CCardHeader>
              <div className="badge-valign">
                <i
                  className="fa fa-align-justify"
                  style={{ marginRight: '8px' }}
                />
                쿠폰 코드 상세{' '}
                <CBadge color="primary">{couponCodeDetail.id}</CBadge>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol md={2}>ID</CCol>
                <CCol>{couponCodeDetail.id}</CCol>
              </CRow>
              <CRow>
                <CCol md={2}>CODE</CCol>
                <CCol>
                  <code>{couponCodeDetail.code}</code>
                </CCol>
              </CRow>
              <CRow>
                <CCol md={2}>생성일</CCol>
                <CCol>
                  {moment(couponCodeDetail.created_at).format(
                    'YYYY-MM-DD hh:mm:ss a',
                  )}
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
          <CCard>
            <CCardHeader>
              <div className="badge-valign">
                <i
                  className="fa fa-align-justify"
                  style={{ marginRight: '8px' }}
                />
                쿠폰 상세 <CBadge color="primary">{couponDetail.id}</CBadge>
              </div>
            </CCardHeader>
            <CCardBody>
              <CouponGroupDetail courseData={courseData} {...couponDetail} />
            </CCardBody>
          </CCard>
          <div className="animated fadeIn">
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    <i
                      className="fa fa-align-justify"
                      style={{ marginRight: '8px' }}
                    />
                    쿠폰 코드 <code>{couponCodeDetail.code}</code> 등록한 회원
                    목록
                  </CardHeader>
                  <CardBody>
                    <UserCouponCodeListTable
                      search={{
                        coupon_code: couponCodeDetail.code,
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
};

export default CouponCodeDetailPage;
