import React, { useEffect, useState } from 'react';

import BlockUi from 'react-block-ui';
import { CouponGroupForm } from '../../components/CouponGroupForm';
import axios from 'axios';
import config from '../../config';
import moment from 'moment';
import CouponGroupListNav from '../../components/CouponGroupListNav';

export const CouponGroupCreate = (props) => {
  const [courseData, setCourseData] = useState([]);
  const [blocking, setBlocking] = useState(false);
  const { history } = props;

  const getCourseData = () => {
    const endpoint = `${config.API_BASE}/admin/api/course/v1/courses?per_page=100`;
    axios
      .get(endpoint)
      .then((res) => {
        setCourseData(res.data.data);
      })
      .catch((err) => {
        alert('데이터를 가져오는 중 오류가 발생하였습니다.');
        console.log(err);
      });
  };

  useEffect(() => {
    getCourseData();
  }, []);

  const onSubmit = (value) => {
    setBlocking(true);
    const endpoint = `${config.API_BASE}/admin/api/coupons/coupons`;

    ['expires_at', 'start_at', 'use_expires_at'].map((key, idx) => {
      if (moment.isMoment(value[key])) {
        value[key] = value[key].format();
      }
    });

    ['course_duration', 'max_use_count', 'use_expires_in_days'].map(
      (key, idx) => {
        if (typeof value[key] === 'string') {
          value[key] = parseInt(value[key]);
        }
      },
    );

    value.course_ids = value.course_ids.map((courseId, idx) => courseId);

    value.is_active = value.is_active === 'true';

    axios
      .post(endpoint, value)
      .then((res) => {
        const couponGroupId = res.data.data.id;
        history.push(`/coupons/coupon-groups/${couponGroupId}`);
        setBlocking(false);
      })
      .catch((err) => {
        console.log(err);
        alert('데이터 처리중 오류가 발생하였습니다.');
        setBlocking(false);
      });
  };

  return (
    <>
      <CouponGroupListNav />

      <BlockUi
        tag="div"
        blocking={blocking}
        message="쿠폰을 생성하고 있습니다."
      >
        <CouponGroupForm courseData={courseData} onSubmit={onSubmit} />
      </BlockUi>
    </>
  );
};
