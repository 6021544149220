import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CInputCheckbox,
  CLabel,
  CRow,
} from '@coreui/react';
import React, { useState } from 'react';

import CIcon from '@coreui/icons-react';
import { useForm } from 'react-hook-form';

export const GenerateCouponCodeForm = (props) => {
  const [showForm, setShowForm] = useState(false);
  const { handleSubmit, register, errors } = useForm();
  const [prefixEnabled, setPrefixEnabled] = useState(false);
  const [postfixEnabled, setPostfixEnabled] = useState(false);
  const { onSubmit } = props;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CCard>
        <CCardHeader>
          {showForm ? (
            <div className="card-header-actions">
              <CButton
                color="primary"
                size="sm"
                variant="outline"
                onClick={() => setShowForm(false)}
              >
                닫기
              </CButton>
            </div>
          ) : (
            <div className="card-header-actions">
              <CButton
                color="primary"
                size="sm"
                variant="outline"
                onClick={() => setShowForm(true)}
              >
                보이기
              </CButton>
            </div>
          )}
          쿠폰 코드 생성
        </CCardHeader>

        {showForm && (
          <>
            <CCardBody>
              <CRow>
                <CCol xs="12">
                  <CFormGroup>
                    <CLabel htmlFor="name">
                      생성할 쿠폰 갯수 <small>coupon count</small>
                    </CLabel>
                    <CInput
                      id="count"
                      name="count"
                      placeholder="1"
                      innerRef={register({
                        required: 'Required',
                      })}
                      required
                    />
                    {errors.name && <p>{errors.name.message}</p>}
                  </CFormGroup>
                </CCol>
              </CRow>
              <CCard>
                <CCardHeader>문자열 고정</CCardHeader>

                <CCardBody>
                  <CRow>
                    <CCol xs="3">
                      <CFormGroup>
                        <CInput
                          id="prefix"
                          name="prefix"
                          innerRef={register}
                          disabled={!prefixEnabled}
                        />
                      </CFormGroup>

                      <CFormGroup variant="checkbox" className="checkbox">
                        <CInputCheckbox
                          id="usePrefix"
                          name="checkbox"
                          value
                          checked={prefixEnabled}
                          onChange={(e) => setPrefixEnabled(e.target.checked)}
                        />
                        <CLabel
                          variant="checkbox"
                          className="form-check-label"
                          htmlFor="usePrefix"
                        >
                          시작 문자열 지정
                        </CLabel>
                      </CFormGroup>
                    </CCol>
                    <CCol xs="3">
                      <CInput
                        // id="duration"
                        // name="duration"
                        disabled
                      />
                    </CCol>
                    <CCol xs="3">
                      <CInput
                        // id="duration"
                        // name="duration"
                        disabled
                        // innerRef={register({
                        //   required: 'Required',
                        // })}
                      />
                    </CCol>
                    <CCol xs="3">
                      <CFormGroup>
                        <CInput
                          id="postfix"
                          name="postfix"
                          innerRef={register}
                          disabled={!postfixEnabled}
                        />
                      </CFormGroup>

                      <CFormGroup variant="checkbox" className="checkbox">
                        <CInputCheckbox
                          id="usePostfix"
                          name="checkbox"
                          value
                          checked={postfixEnabled}
                          onChange={(e) => setPostfixEnabled(e.target.checked)}
                        />
                        <CLabel
                          variant="checkbox"
                          className="form-check-label"
                          htmlFor="usePostfix"
                        >
                          끝 문자열 지정
                        </CLabel>
                      </CFormGroup>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCardBody>

            <CCardFooter>
              <div style={{ padding: '4px 0 8px 0', textAlign: 'right' }}>
                <CButton type="submit" size="lg" color="primary">
                  <CIcon name="cil-check" /> 쿠폰 코드 생성
                </CButton>{' '}
                <CButton type="reset" size="lg" color="danger">
                  <CIcon name="cil-ban" /> Reset
                </CButton>
              </div>
            </CCardFooter>
          </>
        )}
      </CCard>
    </form>
  );
};
