import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import React, { Component } from 'react';

import VoucherNav from '../../components/VoucherNav';
import VoucherListTable from '../../components/VoucherListTable';

const VoucherListPage = () => (
  <div className="animated fadeIn">
    <VoucherNav />

    <Row>
      <Col>
        <Card>
          <CardHeader>
            <i className="fa fa-align-justify" /> 이용권 목록
          </CardHeader>
          <CardBody>
            <VoucherListTable />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);

export default VoucherListPage;
