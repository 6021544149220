import React from 'react';

import { CNavbar, CNavbarBrand, CToggler } from '@coreui/react';

const UserCouponCodeNav = () => (
  <>
    <CNavbar
      expandable="sm"
      color="faded"
      light
      style={{
        border: '1px solid #d8dbe0',
        backgroundColor: '#ffffff',
        borderRadius: '0.25rem',
        marginBottom: '1.5rem',
      }}
    >
      <CToggler inNavbar />
      <CNavbarBrand>등록 쿠폰</CNavbarBrand>
    </CNavbar>
  </>
);

UserCouponCodeNav.defaultProps = {};

UserCouponCodeNav.propTypes = {};

export default UserCouponCodeNav;
