import axios from 'axios';
import config from '../config';
import { history } from './history';
import session from './Session';

axios.interceptors.request.use((cfg) => {
  if (cfg.url === config.TOKEN_URL) {
    return cfg;
  }
  if (
    cfg.url.startsWith(config.API_BASE) ||
    cfg.url.startsWith(config.USERINFO_URL)
  ) {
    // eslint-disable-next-line no-param-reassign
    cfg.headers.authorization = `Bearer ${session.getAccessToken()}`;
  }

  return cfg;
});
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let errResponseStatus = null;
    const originalRequest = error.config;
    const { TOKEN_URL } = config;

    try {
      errResponseStatus = error.response.status;
      // eslint-disable-next-line no-empty
    } catch (e) {}

    if (originalRequest.url === TOKEN_URL) {
      console.log('TOKEN REQUEST ERROR');
    }

    if (
      (error.message === 'Network Error' || errResponseStatus === 401) &&
      !originalRequest.retry
    ) {
      const refreshToken = session.getRefreshToken();
      originalRequest.retry = true;

      if (refreshToken) {
        const headers = {
          Authorization: `Basic ${btoa(
            `${config.clientId}:${config.clientSecret}`,
          )}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        };
        const params = new URLSearchParams({
          grant_type: 'refresh_token',
          refresh_token: refreshToken,
        });

        return axios
          .post(config.TOKEN_URL, params.toString(), { headers })
          .then((res) => {
            const { access_token, refresh_token } = res.data;
            session.setAccessToken(access_token);
            session.setRefreshToken(refresh_token);
            originalRequest.headers.authorization = `Bearer ${access_token}`;

            return axios(originalRequest);
          })
          .catch(() => {
            session.logout();
            history.push('/#/login');
            window.location.reload();
            return false;
          });
      }

      return Promise.reject(error);
    }

    return Promise.reject(error);
  },
);
