export default {
  clientId: window.RUNTIME_OAUTH_CLIENT_ID
    ? window.RUNTIME_OAUTH_CLIENT_ID
    : 'proxy-client',
  clientSecret: window.RUNTIME_OAUTH_CLIENT_SECRET
    ? window.RUNTIME_OAUTH_CLIENT_SECRET
    : 'd60227dc-ac65-4f6c-88fd-42fb76530858',
  defaultScope: 'openid email profile',
  API_BASE: window.RUNTIME_API_BASE
    ? window.RUNTIME_API_BASE
    : 'https://backoffice-dev.kong.yk8s.me',
  AUTH_BASE: window.RUNTIME_OAUTH_BASE
    ? window.RUNTIME_OAUTH_BASE
    : 'https://keycloak.kong.yk8s.me/auth/realms/tarangire-dev/protocol/openid-connect',
  TOKEN_URL: window.RUNTIME_OAUTH_TOKEN_URL
    ? window.RUNTIME_OAUTH_TOKEN_URL
    : 'https://keycloak.kong.yk8s.me/auth/realms/tarangire-dev/protocol/openid-connect/token',
  USERINFO_URL: window.RUNTIME_OAUTH_USERINFO_URL
    ? window.RUNTIME_OAUTH_USERINFO_URL
    : 'https://keycloak.kong.yk8s.me/auth/realms/tarangire-dev/protocol/openid-connect/userinfo',
  loginUser: window.RUNTIME_DEFAULT_USER,
  loginPassword: window.RUNTIME_DEFAULT_PASSWORD,
};
