import { CCard, CCardBody, CCardHeader } from '@coreui/react';

import React from 'react';
import CouponActivityListTable from '../../components/CouponActivityListTable';
import CouponLogNav from '../../components/CouponLogNav';

const CouponActivityListPage = () => (
  <>
    <CouponLogNav />
    <CCard>
      <CCardHeader>
        <i className="fa fa-align-justify" style={{ marginRight: '8px' }} />
        쿠폰 Logs
      </CCardHeader>
      <CCardBody>
        <CouponActivityListTable />
      </CCardBody>
    </CCard>
  </>
);

export default CouponActivityListPage;
