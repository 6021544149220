import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import CouponGroupListTable from '../../components/CouponGroupListTable';
import CouponGroupListNav from '../../components/CouponGroupListNav';

const CouponListPage = () => (
  <div className="animated fadeIn">
    <CouponGroupListNav />

    <Row>
      <Col>
        <Card>
          <CardHeader>
            <i className="fa fa-align-justify" /> 쿠폰 목록
          </CardHeader>
          <CardBody>
            <CouponGroupListTable />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);

export default CouponListPage;
