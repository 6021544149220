const gnb = [
  {
    path: '/dashboard',
    name: 'Dashboard',
  },
  {
    path: '/users',
    name: '회원',
  },
  {
    path: '/courses',
    name: '강의',
  },
  {
    path: '/coupons',
    name: '쿠폰 및 이용권',
  },
];

export default gnb;
