import React from 'react';
import PropTypes from 'prop-types';

import {
  CBadge,
  CCard,
  CCardBody,
  CCol,
  CCollapse,
  CNav,
  CNavbar,
  CNavbarBrand,
  CNavbarNav,
  CNavItem,
  CNavLink,
  CRow,
  CToggler,
} from '@coreui/react';

const CouponGroupNav = (props) => {
  const { couponGroupId, couponStats } = props;
  return (
    <>
      <CNavbar
        expandable="sm"
        color="faded"
        light
        style={{
          border: '1px solid #d8dbe0',
          borderBottom: 'none',
          backgroundColor: '#ffffff',
          borderRadius: '0.25rem',
        }}
      >
        <CToggler inNavbar />
        <CNavbarBrand>
          쿠폰 상세 <CBadge color="primary">{couponGroupId}</CBadge>
        </CNavbarBrand>
        <CCollapse show navbar>
          <CNavbarNav className="ml-auto" />

          <CNav>
            <CNavItem>
              <CNavLink active to={`/coupons/coupon-groups/${couponGroupId}`}>
                쿠폰 상세
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                to={`/coupons/coupon-groups/${couponGroupId}/coupon-codes`}
              >
                쿠폰 코드
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                to={`/coupons/coupon-groups/${couponGroupId}/user-coupon-codes`}
              >
                등록 쿠폰
              </CNavLink>
            </CNavItem>
          </CNav>
        </CCollapse>
      </CNavbar>
      <CCard>
        <CCardBody>
          <CRow>
            <CCol md={2}>등록된 쿠폰 코드 갯수</CCol>
            <CCol>
              <strong>{couponStats.coupon_count}</strong> 개
            </CCol>
          </CRow>
          <CRow>
            <CCol md={2}>쿠폰 코드를 등록한 회원 수</CCol>
            <CCol md={4}>
              <strong>{couponStats.registered_user_count}</strong> 명
            </CCol>
            <CCol md={2}>쿠폰 코드를 사용한 회원 수</CCol>
            <CCol md={4}>
              <strong>{couponStats.used_user_count}</strong> 명
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </>
  );
};

CouponGroupNav.defaultProps = {
  couponGroupId: null,
  couponStats: {},
};

CouponGroupNav.propTypes = {
  couponGroupId: PropTypes.number,
  couponStats: PropTypes.shape({
    coupon_count: PropTypes.number,
    registered_user_count: PropTypes.number,
    used_user_count: PropTypes.number,
  }),
};

export default CouponGroupNav;
