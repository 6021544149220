import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
} from '@coreui/react';
import React, { useEffect, useRef, useState } from 'react';

import BlockUi from 'react-block-ui';
import { CouponGroupDetail } from '../../components/CouponGroupDetail';
import { CouponGroupForm } from '../../components/CouponGroupForm';
import axios from 'axios';
import config from '../../config';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import CouponGroupNav from '../../components/CouponGroupNav';

const CouponGroupDetailPage = (props) => {
  const { id: couponGroupId } = props.match.params;
  const { history } = props;

  const [couponDetail, setCouponDetail] = useState({});
  const [couponStats, setCouponStats] = useState({});
  const [viewMode, setViewMode] = useState('detail');
  const [courseData, setCourseData] = useState([]);
  const [blockingCouponEdit, setBlockingCouponEdit] = useState(false);
  const [
    blockingGenerateCouponCodeForm,
    setBlockingGenerateCouponCodeForm,
  ] = useState(false);

  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);

  const dataTable = useRef();

  const handleCouponEditSubmit = (value) => {
    setBlockingCouponEdit(true);
    const endpoint = `${config.API_BASE}/admin/api/coupons/coupons/${couponGroupId}`;

    ['expires_at', 'start_at', 'use_expires_at'].map((key, idx) => {
      if (moment.isMoment(value[key])) {
        value[key] = value[key].format();
      }
    });

    ['course_duration', 'max_use_count', 'use_expire_in_days'].map(
      (key, idx) => {
        if (typeof value[key] === 'string') {
          value[key] = parseInt(value[key]);
        }
      },
    );

    value.course_ids = value.course_ids.map((courseId, idx) => courseId);

    value.is_active = value.is_active === 'true';

    axios
      .post(endpoint, value)
      .then((res) => {
        setCouponDetail(res.data.data);
        setBlockingCouponEdit(false);
        setViewMode('detail');
      })
      .catch((err) => {
        console.log(err);
        setBlockingCouponEdit(false);
      });
  };

  const showCouponDeleteConfirmModal = () => {
    setDeleteConfirmModal(true);
  };

  const handleCouponDelete = () => {
    const endpoint = `${config.API_BASE}/admin/api/coupons/coupons/${couponGroupId}`;
    axios
      .delete(endpoint)
      .then((res) => {
        history.push('/coupons/coupon-groups/list');
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCouponDetail = async () => {
    const endpoint = `${config.API_BASE}/admin/api/coupons/coupons/${couponGroupId}`;

    try {
      const res = await axios.get(endpoint);
      setCouponDetail(res.data.data);
    } catch (e) {
      console.log(e);
      setCouponDetail(null);
    }
  };

  const getCouponStats = async () => {
    const endpoint = `${config.API_BASE}/admin/api/coupons/coupons/${couponGroupId}/stats`;

    try {
      const res = await axios.get(endpoint);
      setCouponStats(res.data.data);
    } catch (e) {
      console.log(e);
      setCouponStats(null);
    }
  };

  const getCourseData = () => {
    const endpoint = `${config.API_BASE}/admin/api/course/v1/courses?per_page=100`;
    axios
      .get(endpoint)
      .then((res) => {
        setCourseData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCouponDetail();
    getCouponStats();
    getCourseData();
  }, []);

  return (
    <>
      <>
        <CModal
          show={deleteConfirmModal}
          onClose={() => {
            setDeleteConfirmModal(false);
          }}
        >
          <CModalHeader closeButton>쿠폰 삭제 확인</CModalHeader>
          <CModalBody>
            쿠폰: <strong>{couponDetail.name}</strong>{' '}
            <CBadge color="primary">{couponDetail.id}</CBadge>을
            삭제하시겠습니까?
          </CModalBody>
          <CModalFooter>
            <CButton color="primary" onClick={handleCouponDelete}>
              삭제
            </CButton>{' '}
            <CButton
              color="secondary"
              onClick={() => {
                setDeleteConfirmModal(false);
              }}
            >
              취소
            </CButton>
          </CModalFooter>
        </CModal>
      </>

      <CouponGroupNav couponGroupId={couponGroupId} couponStats={couponStats} />

      <CRow>
        <CCol>
          {viewMode === 'detail' && (
            <>
              <CCard>
                <CCardHeader>
                  <div className="card-header-actions">
                    <CButton
                      color="primary"
                      size="sm"
                      variant="outline"
                      style={{ marginRight: '6px' }}
                      onClick={() => setViewMode('edit')}
                    >
                      수정하기
                    </CButton>
                    <CButton
                      color="danger"
                      size="sm"
                      variant="outline"
                      onClick={showCouponDeleteConfirmModal}
                    >
                      삭제하기
                    </CButton>
                  </div>
                  쿠폰 상세
                </CCardHeader>
                <CCardBody>
                  <CouponGroupDetail
                    courseData={courseData}
                    {...couponDetail}
                  ></CouponGroupDetail>
                </CCardBody>
              </CCard>
              <CCard>
                <CCardHeader>쿠폰 사용 현황</CCardHeader>
                <CCardBody>
                  <CRow>
                    <CCol md={2}>등록된 쿠폰 코드 갯수</CCol>
                    <CCol>
                      <strong>{couponStats.coupon_count}</strong> 개
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>쿠폰 코드를 등록한 회원 수</CCol>
                    <CCol md={4}>
                      <strong>{couponStats.registered_user_count}</strong> 명
                    </CCol>
                    <CCol md={2}>쿠폰 코드를 사용한 회원 수</CCol>
                    <CCol md={4}>
                      <strong>{couponStats.used_user_count}</strong> 명
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </>
          )}
          {viewMode === 'edit' && (
            <BlockUi
              tag="div"
              blocking={blockingCouponEdit}
              message="쿠폰을 수정하고 있습니다."
            >
              <CouponGroupForm
                mode="edit"
                defaultValues={couponDetail}
                courseData={courseData}
                onSubmit={handleCouponEditSubmit}
                formHeader={
                  <CCardHeader>
                    <div className="card-header-actions">
                      <CButton
                        color="primary"
                        size="sm"
                        variant="outline"
                        onClick={() => setViewMode('detail')}
                      >
                        닫기
                      </CButton>
                    </div>
                    쿠폰 수정
                  </CCardHeader>
                }
              />
            </BlockUi>
          )}
        </CCol>
      </CRow>
    </>
  );
};

export default withRouter(CouponGroupDetailPage);
