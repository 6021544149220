import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import BlockUi from 'react-block-ui';
import VoucherForm from '../../components/VoucherForm';
import axios from 'axios';
import config from '../../config';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import VoucherNav from '../../components/VoucherNav';

const VoucherCreate = inject('store')(
  observer((props) => {
    const [courseData, setCourseData] = useState([]);
    const [defaultValues, setDefaultValues] = useState({
      src_type: 'admin',
    });
    const [blocking, setBlocking] = useState(false);
    const { history, store } = props;

    const getCourseData = () => {
      const endpoint = `${config.API_BASE}/admin/api/course/v1/courses?per_page=100`;
      axios
        .get(endpoint)
        .then((res) => {
          setCourseData(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    useEffect(() => {
      getCourseData();
      setDefaultValues({
        src_type: 'admin',
        src_id: store.profile ? store.profile.sub : 'xxx',
      });
    }, [store]);

    const onSubmit = (value) => {
      setBlocking(true);

      const endpoint = `${config.API_BASE}/admin/api/coupons/vouchers`;

      ['expires_at'].map((key) => {
        if (moment.isMoment(value[key])) {
          value[key] = value[key].format();
        }
      });

      ['course_duration', 'course_group_id', 'user_id'].map((key) => {
        if (typeof value[key] === 'string') {
          value[key] = parseInt(value[key]);
        }
      });

      console.log('onSubmit', { value });

      axios
        .post(endpoint, value)
        .then((res) => {
          const couponGroupId = res.data.data.id;
          history.push(`/coupons/vouchers/list`);
          setBlocking(false);
        })
        .catch((err) => {
          console.log(err);
          setBlocking(false);
        });
    };

    return (
      <>
        <VoucherNav />

        <BlockUi
          tag="div"
          blocking={blocking}
          message="이용권을 생성하고 있습니다."
        >
          <VoucherForm
            defaultValues={{
              ...defaultValues,
              ...{ src_id: store.profile ? store.profile.sub : null },
            }}
            courseData={courseData}
            onSubmit={onSubmit}
          />
        </BlockUi>
      </>
    );
  }),
);
export default withRouter(VoucherCreate);
