import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
} from '@coreui/react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import React from 'react';
import session from '../../lib/Session';

const Profile = inject('store')(
  observer(({ history, store }) => {
    const handleLogout = () => {
      session.logout();
      history.push('/login');
    };

    return (
      <>
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>
                <div className="card-header-actions">
                  <CButton
                    color="primary"
                    size="sm"
                    variant="outline"
                    onClick={() => handleLogout()}
                  >
                    로그아웃
                  </CButton>
                </div>
                관리자 프로필
              </CCardHeader>
              <CCardBody>
                {store.profile && (
                  <>
                    <CRow>
                      <CCol md={2}>ID</CCol>
                      <CCol>
                        <code>{store.profile.sub}</code>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md={2}>이름</CCol>
                      <CCol>{store.profile.name}</CCol>
                    </CRow>
                    <CRow>
                      <CCol md={2}>Email</CCol>
                      <CCol>{store.profile.email}</CCol>
                    </CRow>
                  </>
                )}
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </>
    );
  }),
);

export default withRouter(Profile);
