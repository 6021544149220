import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react';
import React, { useEffect, useRef, useState } from 'react';
import CouponCodeListTable from '../../components/CouponCodeListTable';
import axios from 'axios';
import config from '../../config';
import { withRouter } from 'react-router-dom';
import CouponGroupNav from '../../components/CouponGroupNav';
import BlockUi from 'react-block-ui';
import { GenerateCouponCodeForm } from '../../components/GenerateCouponCodeForm';

const CouponCouponCodeListPage = (props) => {
  const { id: couponGroupId } = props.match.params;
  const [couponStats, setCouponStats] = useState({});
  const [
    blockingGenerateCouponCodeForm,
    setBlockingGenerateCouponCodeForm,
  ] = useState(false);

  const dataTable = useRef();

  const getCouponStats = async () => {
    const endpoint = `${config.API_BASE}/admin/api/coupons/coupons/${couponGroupId}/stats`;

    try {
      const res = await axios.get(endpoint);
      setCouponStats(res.data.data);
    } catch (e) {
      console.log(e);
      setCouponStats(null);
    }
  };

  useEffect(() => {
    getCouponStats();
  }, []);

  const handleGenerateCouponCodeFormSubmit = (values) => {
    setBlockingGenerateCouponCodeForm(true);

    const endpoint = `${config.API_BASE}/admin/api/coupons/coupons/${couponGroupId}/generate-coupon-codes`;

    let data = { ...values };

    axios
      .post(endpoint, data)
      .then((res) => {
        setBlockingGenerateCouponCodeForm(false);
        dataTable.current.reloadItemList();
        getCouponStats();
      })
      .catch((err) => {
        setBlockingGenerateCouponCodeForm(false);
        console.log(err);
      });
  };

  return (
    <>
      <CouponGroupNav couponGroupId={couponGroupId} couponStats={couponStats} />

      <CRow>
        <CCol>
          <BlockUi
            tag="div"
            blocking={blockingGenerateCouponCodeForm}
            message="쿠폰 코드를 생성하고 있습니다."
          >
            <GenerateCouponCodeForm
              onSubmit={handleGenerateCouponCodeFormSubmit}
            />
          </BlockUi>
        </CCol>
      </CRow>

      <CRow>
        <CCol>
          <CCard>
            <CCardHeader>쿠폰 코드 목록</CCardHeader>
            <CCardBody>
              <CouponCodeListTable
                ref={dataTable}
                readOnlyCouponId={true}
                search={{ coupon_group_id: couponGroupId }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default withRouter(CouponCouponCodeListPage);
