import { CNavbar, CNavbarBrand, CToggler } from '@coreui/react';
import React from 'react';

const CouponLogNav = () => (
  <>
    <CNavbar
      expandable="sm"
      color="faded"
      light
      style={{
        border: '1px solid #d8dbe0',
        backgroundColor: '#ffffff',
        borderRadius: '0.25rem',
        marginBottom: '1.5rem',
      }}
    >
      <CToggler inNavbar />
      <CNavbarBrand>쿠폰 로그</CNavbarBrand>
    </CNavbar>
  </>
);

CouponLogNav.defaultProps = {};

CouponLogNav.propTypes = {};

export default CouponLogNav;
