import { Link } from 'react-router-dom';
import React from 'react';

export const UserInline = (props) => {
  const { userId } = props;
  return (
    <div>
      <i className="fa fa-user text-primary" style={{ marginRight: '4px' }} />
      <Link to={`/users/${userId}`}>{userId}</Link>
    </div>
  );
};
