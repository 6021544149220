export default [
  {
    _tag: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
    badge: {
      color: 'info',
      text: 'NEW',
    },
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['관리'],
  },
  {
    _tag: 'CSidebarNavItem',
    name: '회원',
    to: '/users',
    icon: 'cil-list',
  },
  {
    _tag: 'CSidebarNavItem',
    name: '강의',
    to: '/lectures',
    icon: 'cil-list',
  },
  {
    _tag: 'CSidebarNavItem',
    name: '쿠폰 및 이용권',
    to: '/coupons',
    icon: 'cil-list',
  },
];
