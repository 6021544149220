import { CourseDashboard } from './CourseDashboard';
import { CourseDetailPage } from './CourseDetailPage';
import CourseList from './CourseList';
import { SubscriptionList } from './SubscriptionList';

export const routes = [
  {
    path: '/courses',
    name: '강의',
    exact: true,
    component: CourseDashboard,
  },
  {
    path: '/courses/list',
    name: '목록',
    exact: true,
    component: CourseList,
  },
  {
    path: '/courses/:courseId',
    name: '강의 상세',
    exact: true,
    component: CourseDetailPage,
  },
  {
    path: '/courses/subscriptions/list',
    name: '사용자 수강 목록',
    exact: true,
    component: SubscriptionList,
  },
];
